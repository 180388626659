// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginSuccess: path(ROOTS_AUTH, '/login-success'),
  register: path(ROOTS_AUTH, '/register'),
  activate: path(ROOTS_AUTH, '/activate'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  howItWorks: '/how-it-works',
  terms: '/terms',
  privacy: '/privacy',
  imprint: '/imprint',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    portraits: path(ROOTS_DASHBOARD, '/portraits'),
    purchaseOptions: path(ROOTS_DASHBOARD, '/purchaseOptions'),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
    upload: path(ROOTS_DASHBOARD, '/profile/upload'),
    show: path(ROOTS_DASHBOARD, '/profile/show'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name: any) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: any) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
  },
  info: {
    howItWorks: path(ROOTS_DASHBOARD, '/info/how-it-works'),
    contact: path(ROOTS_DASHBOARD, '/info/contact'),
    faqs: path(ROOTS_DASHBOARD, '/info/faqs'),
    terms: path(ROOTS_DASHBOARD, '/info/terms'),
    privacy: path(ROOTS_DASHBOARD, '/info/privacy'),
    imprint: path(ROOTS_DASHBOARD, '/info/imprint'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

// Utility function to flatten the PATH_DASHBOARD object
type PathsObject = {
  [key: string]: string | PathsObject | ((name: any) => string);
};

// Utility function to flatten the PATH_DASHBOARD object
const flattenPaths = (obj: PathsObject, parentPath: string = ''): { [key: string]: string } => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const path = `${parentPath}/${key}`.replace(/\/+/g, '/');
    if (typeof value === 'string') {
      acc[path] = key.charAt(0).toUpperCase() + key.slice(1);
    } else if (typeof value === 'object') {
      Object.assign(acc, flattenPaths(value as PathsObject, path));
    }
    return acc;
  }, {} as { [key: string]: string });
};
export const PATH_MAP = flattenPaths(PATH_DASHBOARD);
