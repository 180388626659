export enum PictureGenerationResultReason {
  InsufficientBalance = 'InsufficientBalance',
  RateLimitExceeded = 'RateLimitExceeded',
}

export enum DetectionLabels {
  FemaleGenitaliaCovered = 'FEMALE_GENITALIA_COVERED',
  FaceFemale = 'FACE_FEMALE',
  ButtocksExposed = 'BUTTOCKS_EXPOSED',
  FemaleBreastExposed = 'FEMALE_BREAST_EXPOSED',
  FemaleGenitaliaExposed = 'FEMALE_GENITALIA_EXPOSED',
  MaleBreastExposed = 'MALE_BREAST_EXPOSED',
  AnusExposed = 'ANUS_EXPOSED',
  FeetExposed = 'FEET_EXPOSED',
  BellyCovered = 'BELLY_COVERED',
  FeetCovered = 'FEET_COVERED',
  ArmpitsCovered = 'ARMPITS_COVERED',
  ArmpitsExposed = 'ARMPITS_EXPOSED',
  FaceMale = 'FACE_MALE',
  BellyExposed = 'BELLY_EXPOSED',
  MaleGenitaliaExposed = 'MALE_GENITALIA_EXPOSED',
  AnusCovered = 'ANUS_COVERED',
  FemaleBreastCovered = 'FEMALE_BREAST_COVERED',
  ButtocksCovered = 'BUTTOCKS_COVERED',
}

export type DetectionResult = {
  box: [number, number, number, number];
  class: DetectionLabels;
  score: number;
};

export type NudeResult = DetectionResult[];
