import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deepEqual from 'fast-deep-equal';
import { SetStateAction, useAtomValue } from 'jotai';
import { userAtom } from '@/providers/AuthProvider';
import { SocketTypes } from '@api-types';
import { socketAtom } from '@/providers/WebSocketProvider';

interface ReduxState {
  dataCache: {
    cachedData: {
      [key: string]: any; // tmp
    };
  };
}

export const useDataRequest = <T>(q: string, params: {}, manualRefresh?: boolean) => {
  const socket = useAtomValue(socketAtom);
  const dispatch = useDispatch();
  const uniqueCacheKey = useMemo(() => `${q}_${JSON.stringify(params)}`, [q, params]);
  const cachedData = useSelector((state: ReduxState) => state.dataCache.cachedData[uniqueCacheKey]);
  const refresh = useRef(false);
  const [data, setData] = useState<T>(cachedData); // initialize state with cachedData

  const fetchData = useCallback(() => {
    if (!socket) return;

    socket.emit(q, params, (response: { status?: any; error?: any; data?: any }) => {
      if (response.status !== 200) {
        console.error(response.error);
        return;
      }
      const newData = response.data;
      if (!deepEqual(newData, cachedData)) {
        const actionType = newData.length === 0 ? 'dataCache/clearCacheForQuery' : 'dataCache/setNewData';
        dispatch({ type: actionType, payload: { query: uniqueCacheKey, data: newData } });
        setData(newData); // update state with new data
        refresh.current = !refresh.current;
      } else {
        setData(cachedData);
      }
    });
  }, [socket, cachedData, dispatch, q, params, uniqueCacheKey]);

  useEffect(() => {
    fetchData();
  }, [uniqueCacheKey, manualRefresh, socket]);

  return data;
};

export const useStateDataRequest = <T>(
  q: string,
  params: {} | null,
  setData: (update: SetStateAction<T>) => void, // Use Jotai SetStateAction
  manualRefresh?: boolean
) => {
  const socket = useAtomValue(socketAtom);
  const dispatch = useDispatch();
  const user = useAtomValue(userAtom);
  const userId = user?.id;
  const uniqueCacheKey = useMemo(() => `${q}_${JSON.stringify(params)}_${userId}`, [q, params, userId]);
  const cachedData = useSelector((state: ReduxState) => state.dataCache.cachedData[uniqueCacheKey]);

  useEffect(() => {
    if (cachedData) {
      setData(cachedData); // initialize state with cachedData
    }
  }, [cachedData, setData]);

  const fetchData = useCallback(async () => {
    if (!socket) return;

    socket.emit(q, params, (response: { status?: any; error?: any; data?: any }) => {
      if (response.status !== 200) {
        console.error(response.error);
        return;
      }

      const newData = response.data;

      if (!deepEqual(newData, cachedData)) {
        const actionType = newData?.length === 0 ? 'dataCache/clearCacheForQuery' : 'dataCache/setNewData';

        setData(newData); // update state with new data, even if it's empty
        dispatch({ type: actionType, payload: { query: uniqueCacheKey, data: newData } });
      }
    });
  }, [uniqueCacheKey, socket, cachedData, dispatch, q, params, setData]);

  useEffect(() => {
    fetchData();
  }, [uniqueCacheKey, manualRefresh, socket]);
};

export const usePostDataRequest = () => {
  const socket = useAtomValue(socketAtom);

  const emitDataRequestNoCache = <TData = any | void>(q: any, params: any): Promise<SocketTypes.MessageAckCallbackResult<TData>> => {
    return new Promise((resolve, reject) => {
      if (!socket) return;

      socket.emit(q, params, (response: { status: number; error: any; data: any }) => {
        if (response.status !== 200) {
          return reject(response.error);
        }
        resolve({
          status: response.status,
          data: response.data,
        });
      });
    });
  };

  return emitDataRequestNoCache;
};
