import { BaseApi, TranslationMapping } from './_baseApi';

export class UploadApi extends BaseApi {
  constructor(setSnackbarQueue: any, translate: (text: any, options?: any) => string, token: string | null) {
    super(setSnackbarQueue, translate, token);
  }

  public uploadImages = async (formData: FormData, setUploadProgress: (progress: number) => void): Promise<string> => {
    const codeMappings: TranslationMapping = {
      406: 'imagePreviewAndSave.errors.406',
      400: 'imagePreviewAndSave.errors.500',
    };
    try {
      const pictureSetId = await this.postMultipartWithProgress<string>('/upload', formData, codeMappings, (progress) => {
        setUploadProgress(progress); // Update the atom's state
      });
      return pictureSetId;
    } catch (error) {
      console.error('Unexpected error uploading images:', error);
      throw new Error('Failed to upload images');
    }
  };
}
