import { atom, useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useSettingsContext } from '../components/settings';
import { allLangs } from './config-lang';
import { Link } from '@mui/material';
import NextLink from 'next/link';
import { useEffect, useState, useCallback } from 'react';
import { changeLanguage as i18nChangeLanguage } from './i18n';

export const currentLanguageAtom = atom<string>('en');

export const useLocales = <T = any,>(baseFilePath?: string) => {
  const { i18n } = useTranslation();
  const { onChangeDirectionByLang } = useSettingsContext();
  const [currentLangValue, setCurrentLangValue] = useState<string | null>(null);
  const currentLang = allLangs.find((_lang) => _lang.value === currentLangValue) || null;

  const [dataModule, setDataModule] = useState<T | null>(null);
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const setCurrentLanguage = useSetAtom(currentLanguageAtom);

  const handleChangeLanguage = useCallback(
    async (newLanguage: string | undefined) => {
      if (newLanguage && newLanguage !== currentLangValue) {
        await i18nChangeLanguage(newLanguage);
        localStorage.setItem('i18nextLng', newLanguage); // Update localStorage
        setCurrentLangValue(newLanguage); // Update state
        onChangeDirectionByLang(newLanguage);
        setIsLanguageLoaded(false); // Reset language loaded state
      }
    },
    [currentLangValue, onChangeDirectionByLang]
  );

  useEffect(() => {
    const storedLang = localStorage.getItem('i18nextLng');
    if (storedLang) {
      setCurrentLangValue(storedLang);
    }
  }, []);

  useEffect(() => {
    const loadLanguageData = async () => {
      if (baseFilePath && currentLangValue) {
        try {
          const importedModule = await import(`../${baseFilePath}_${currentLangValue}.ts`);
          setDataModule(importedModule as T);
          setIsLanguageLoaded(true); // Set language loaded to true after data is loaded
        } catch (error) {
          console.error(`Failed to load data for language: ${currentLangValue}`, error);
        }
      }
    };

    if (currentLangValue) {
      loadLanguageData();
    }
  }, [currentLangValue, baseFilePath]);

  const interpolateLinks = (text: any, links: string[] = []) => {
    return text.split(/({{.*?}})/g).map((segment: string) => {
      if (segment.startsWith('{{') && segment.endsWith('}}')) {
        const linkUrl = links.shift();
        return linkUrl ? (
          <Link href={linkUrl} component={NextLink}>
            {segment.slice(2, -2)}
          </Link>
        ) : (
          segment
        );
      } else {
        return segment;
      }
    });
  };

  const translate = (text: any, options?: any) => {
    const translatedText = i18n.t(text!, options).toString();

    if (Array.isArray(options)) {
      return interpolateLinks(translatedText, options);
    }

    return translatedText;
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    interpolateLinks,
    currentLang,
    allLangs,
    dataModule,
    isLanguageLoaded, // Expose the loading state
  };
};

export default useLocales;
