import AppConfig from '@/config/config';
import { AppError } from '@/src/types/error';
import { Errors, HttpErrorCodes, HttpStatusCodes } from '@api-types';

export type TranslationMapping = {
  [key: number]: string;
  default?: string;
};

export abstract class BaseApi {
  baseURL: string;
  constructor(private setSnackbarQueue: any, private translate: (text: any, options?: any) => string, private token: string | null) {
    this.baseURL = AppConfig.apiDomain;
  }

  protected postMultipartWithProgress = async <T>(
    url: string,
    data: FormData,
    mappings: TranslationMapping,
    onProgress: (progress: number) => void
  ): Promise<T> => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${this.baseURL}${url}`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress);
        }
      };

      const response = await new Promise<XMLHttpRequest>((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr);
          } else {
            // Create a mock Response object
            const mockResponse = new Response(xhr.responseText, {
              status: xhr.status,
              statusText: xhr.statusText,
              headers: new Headers({ 'Content-Type': xhr.getResponseHeader('Content-Type') || 'application/json' }),
            });
            reject(this.handleError(mockResponse, mappings));
          }
        };
        xhr.onerror = () => reject(new AppError('errors.noResponse', xhr.statusText));
        xhr.send(data);
      });

      return JSON.parse(response.responseText);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected post = async <T>(url: string, payload: any, mappings: TranslationMapping): Promise<T> => {
    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (response.ok) {
        return await response.json();
      }

      throw await this.handleError(response, mappings);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected postVoid = async (url: string, payload?: any, mappings?: TranslationMapping): Promise<void> => {
    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        method: 'POST',
        body: payload ? JSON.stringify(payload) : undefined,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (response.ok) {
        return;
      }

      throw await this.handleError(response, mappings);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected postMultipart = async <T>(url: string, data: FormData, mappings: TranslationMapping): Promise<T> => {
    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        body: data,
      });
      if (response.ok) {
        return await response.json();
      }

      throw await this.handleError(response, mappings);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected postMultipartVoid = async (url: string, data: FormData, mappings: TranslationMapping): Promise<void> => {
    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        body: data,
      });
      if (response.ok) {
        return;
      }

      throw await this.handleError(response, mappings);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected get = async <T>(url: string, mappings: TranslationMapping): Promise<T> => {
    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      if (response.ok) {
        return await response.json();
      }

      throw await this.handleError(response, mappings);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected put = async <T>(url: string, data: any, mappings: TranslationMapping): Promise<T> => {
    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return await response.json();
      }
      throw await this.handleError(response, mappings);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected delete = async <T>(url: string, mappings: TranslationMapping): Promise<void> => {
    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (response.ok) return;

      throw await this.handleError(response, mappings);
    } catch (error) {
      if (error instanceof AppError) throw error;
      throw new AppError('errors.noResponse', (error as Error)?.message);
    }
  };

  protected handleError = async (response: Response, mappings?: TranslationMapping): Promise<AppError> => {
    const error = await this.getTranslationAsync(response, mappings);
    this.setSnackbarQueue((oldQueue: any) => [...oldQueue, { message: this.translate(error.translation), variant: 'error' }]);
    return error;
  };

  protected getTranslationAsync = async (response: Response, mappings?: TranslationMapping): Promise<AppError> => {
    if (!response) {
      return new AppError('errors.noResponse', 'No response from server. Please check your network connection.');
    }

    let code: HttpErrorCodes | HttpStatusCodes = response.status as HttpStatusCodes;
    try {
      const data = await response.json();
      if (data && data.errorCode) {
        code = data.errorCode as HttpErrorCodes;
      }
    } catch (e) {
      console.error(e);
    }

    if (mappings) {
      console.log('mappings', mappings);
      if (mappings[code]) {
        return new AppError(mappings[code]);
      }
      if (mappings[response.status]) {
        return new AppError(mappings[response.status]);
      }
    }

    if (response.status === 500) return new AppError('500');

    if (response.status === 401) return new AppError('401');

    if (mappings?.default) return new AppError(mappings.default);

    return new AppError('unknown', 'Error code not setup in the client');
  };
}
