import { atom } from 'jotai';

export const safeAreaInsetTopAtom = atom(Number(0));
export const safeAreaInsetBottomAtom = atom(Number(0));
export const safeAreaInsetAtom = atom({ top: Number(0), bottom: Number(0) });

export const globalSnackbarMessageAtom = atom<{
  message: string | null;
  variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined;
}>({
  message: null,
  variant: undefined,
});

type SnackbarMessage = {
  message: string;
  variant: 'default' | 'error' | 'success' | 'warning' | 'info';
  oldCoins?: number; // Optional old coin balance
  newCoins?: number; // Optional new coin balance
  oldTrainings?: number; // Optional old training balance
  newTrainings?: number; // Optional new training balance
};

export const defaultSnackbarQueueAtom = atom<SnackbarMessage[]>([]);
export const countingSnackbarQueueAtom = atom<SnackbarMessage[]>([]);
