import { getDefaultStore, useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useStateDataRequest } from '@/src/communication/socket/dataRequest';
import { PictureSetStatus, PictureTypes, SocketEmits } from '@api-types';
import {
  inTrainingAtom,
  readyPictureSetsAtom,
  reloadPictureSetAtom,
  selectedPictureSetAtom,
  showWelcomeAtom,
  inTrainingPictureSetsAtom,
} from '@/src/atoms/upload.states';
import { RESET } from 'jotai/utils';
import { useRouterQuery } from './useRouterQuery';
import { useRouter } from 'next/router';

const findHighestIdPictureSet = (data: PictureTypes.PictureSetViewModel[]) =>
  data.reduce((prev, current) => {
    if (!current || !prev) return current || prev; // Handle null 'current' and 'prev'
    return prev.id > current.id ? prev : current;
  });

const updateSelectedSet = (
  set: PictureTypes.PictureSetViewModel | null,
  setSelectedPictureSet: (set: PictureTypes.PictureSetViewModel | null) => void,
  setShowWelcome: (show: boolean) => void,
  setInTraining: (training: boolean) => void,
  router: ReturnType<typeof useRouter>
) => {
  if (!set) return; // Handle null 'set'
  setSelectedPictureSet(set);
  setShowWelcome(false);
  if (set.status === PictureSetStatus.startTraining) {
    setInTraining(true);
  } else {
    setInTraining(false);
  }

  // Remove switchToPictureSetId from the URL and perform a shallow reload
  const { switchToPictureSetId, ...restQuery } = router.query;
  router.replace(
    {
      pathname: router.pathname,
      query: restQuery,
    },
    undefined,
    { shallow: true }
  );
};

export const usePictureSet = () => {
  const reloadPictureSet = useAtomValue(reloadPictureSetAtom);
  const [data, setData] = useState<PictureTypes.PictureSetViewModel[] | null>(null);
  const [readyPictureSets, setReadyPictureSets] = useAtom(readyPictureSetsAtom);
  const [selectedPictureSet, setSelectedPictureSet] = useAtom(selectedPictureSetAtom);
  const { switchToPictureSetId } = useRouterQuery();
  const [showWelcome, setShowWelcome] = useAtom(showWelcomeAtom);
  const [inTraining, setInTraining] = useAtom(inTrainingAtom);
  const [inTrainingPictureSets, setInTrainingPictureSets] = useAtom(inTrainingPictureSetsAtom);
  const router = useRouter();
  const { pictureSetId } = useRouterQuery();

  useStateDataRequest(
    SocketEmits.GetPictureSetsDataWithIds,
    switchToPictureSetId ? [switchToPictureSetId] : null,
    setData,
    reloadPictureSet
  );

  useEffect(() => {
    if (data && data.length > 0) {
      const readySets = data.filter((pictureSet) => pictureSet?.status === PictureSetStatus.ready);
      const inTrainingSets = data.filter((pictureSet) => pictureSet?.status === PictureSetStatus.startTraining);
      setReadyPictureSets(readySets);
      setInTrainingPictureSets(inTrainingSets);

      if (readySets.length === 0) {
        setSelectedPictureSet(null);
      } else if (!selectedPictureSet || !readySets.some((set) => set?.id === selectedPictureSet.id)) {
        const highestIdPictureSet = findHighestIdPictureSet(readySets);
        setSelectedPictureSet(highestIdPictureSet);
      }

      // const pictureSet = inTrainingPictureSets.find((set) => set?.id === pictureSetId) || inTrainingPictureSets[0];

      if (inTrainingSets.length > 0 && !pictureSetId) {
        setInTraining(true);
      }

      if (switchToPictureSetId) {
        const selectedSet = data.find((ps) => ps?.id === switchToPictureSetId);
        if (selectedSet) {
          updateSelectedSet(selectedSet, setSelectedPictureSet, setShowWelcome, setInTraining, router);
        }
      }
    } else if (data !== null) {
      setShowWelcome(true);
    }
  }, [switchToPictureSetId, data, selectedPictureSet, pictureSetId]);

  const prevSelectedPictureSetRef = useRef<PictureTypes.PictureSetViewModel | null>(null);

  useEffect(() => {
    if (readyPictureSets.length > 0 && (!selectedPictureSet || !readyPictureSets.some((set) => set?.id === selectedPictureSet.id))) {
      const newSelectedSet = readyPictureSets[0] ?? null;
      if (newSelectedSet !== prevSelectedPictureSetRef.current) {
        prevSelectedPictureSetRef.current = newSelectedSet;
        setSelectedPictureSet(newSelectedSet);
      }
    }
  }, [readyPictureSets, selectedPictureSet]);

  return {
    readyPictureSets,
    selectedPictureSet,
    showWelcome,
    setSelectedPictureSet,
  };
};

export const resetGlobalAtoms = () => {
  const store = getDefaultStore();
  store.set(selectedPictureSetAtom, RESET);
  store.set(showWelcomeAtom, RESET);
  store.set(inTrainingAtom, RESET);
  store.set(readyPictureSetsAtom, RESET);
  store.set(inTrainingPictureSetsAtom, RESET);
};
