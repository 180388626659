import { alpha } from '@mui/system';

// ----------------------------------------------------------------------

// SETUP COLORS

// const GREY = {
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#212B36',
//   900: '#161C24',
// };

// const GREY = {
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#242526', // Dark but elegant
//   900: '#121212',
// };

const GREY = {
  0: '#FFFFFF',
  100: '#F5F5F5',
  200: '#E0E0E0',
  300: '#BDBDBD',
  400: '#9E9E9E',
  500: '#7E7E7E',
  600: '#616161',
  700: '#424242',
  800: '#191919', // More in line with #0D0D0D
  900: '#0D0D0D', // Exactly your shade
};

const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#91E2A2', // Moderately light green with a cozy tint
  light: '#57BE76', // Friendly medium green
  main: '#2F8F4E', // Main cozy dark green
  dark: '#236B3A', // Darker green with warm tone
  darker: '#184D29', // Rich darkest green
  contrastText: '#FFFFFF', // White text for contrast
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: { black: '#000000', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      menu: GREY[700],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: 'rgba(29,28,32,0.08)', default: '#FFFFFF', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
      glas: alpha(GREY[900], 0.76),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
    // shadows: Array(25).fill('0px 3px 6px rgba(0, 0, 0, 0.16)'), // Fill array with your "standard" shadow
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)', // Your hover shadow here
            },
          },
        },
      },
    },
  };

  return themeMode === 'light' ? light : dark;
}
